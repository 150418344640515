import React from 'react'

import { Select } from 'antd'

import { ReactComponent as CommentIcon } from './comment.svg'

import './Sort.scss'

const Sort = ({changeHandler, options, selected}) => {
  return (
    <div className="c-sort">
      <label className="c-sort__label" htmlFor="age-filter">
        <CommentIcon />
        See how recommendations change over time
      </label>
      <Select
      placeholder="Select patient age"
      id="age-filter"
      options={options}
      onChange={changeHandler}
      value={selected ? selected : null}
      size="large">
      </Select>
    </div>
  )
}
export default Sort
