import React from 'react'

import Print from '../../components/Print/Print'
import Share from '../../components/Share/Share'

import './Utils.scss'

const Utils = ({lastUpdated}) => {
  return (
    <div className="c-utils">
      <div className="c-utils__col">
        {lastUpdated ? (
          <p className="c-updated">Last updated on {lastUpdated}</p>
        ) : null}
      </div>
      <div className="c-utils__col">
        <Print />
        <Share />
      </div>
    </div>
  )
}

export default Utils
