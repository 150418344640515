import React from 'react'

import { ReactComponent as CalendarIcon } from './calendar.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as CaveatIcon } from './caveat.svg'

import './Recommendations.scss'

const Recommendation = ({ description, caveat, frequency, min, max } ) => {

  return (
    <li className="c-recommendation">
      <h3 className="c-recommendation__name">{description}</h3>

      <dl className="c-recommendation__stats">
        {frequency ? (
          <div className="c-recommendation__stats-row">
            <dt><CalendarIcon /> Frequency:</dt>
            <dd>{frequency}</dd>
          </div>
        ) : null }

        {min || max ? (
          <div className="c-recommendation__stats-row">
            <dt><UserIcon />Ages:</dt>
            <dd>{min === 0 ? 'Newborn' : min} to {max === 100 ? 'Adulthood' : max}</dd>
          </div>
        ) : null}

        {caveat ? (
          <div className="c-recommendation__stats-row">
            <dt><CaveatIcon /> Caveats:</dt>
            <dd>{caveat}</dd>
          </div>
        ) : null }
      </dl>
    </li>
  )
}

const Recommendations = (props) => {
  const { recommendations, ageRange } = props

  if (!recommendations.length) return null

  let recommenationsSorted = null
  if (ageRange) {
    const rangePieces = ageRange.split('-')
    const min = Number(rangePieces[0])
    const max = Number(rangePieces[1])

    if (min === 0 && max === 0) {
      recommenationsSorted = recommendations.filter(recommendation => min === recommendation.min && max === recommendation.max)
    } else {
      recommenationsSorted = recommendations.filter(recommendation => min >= recommendation.min && max <= recommendation.max)
    }
  } else {
    recommenationsSorted = recommendations
  }

  const first = recommendations[0];
  const { min: fMin, max: fMax } = first;
  return (
    <>
      <h2 className="c-recommendations__heading">Recommendations for Ages: {fMin === 0 ? 'Newborn' : fMin} - {fMax === 100 ? 'Adulthood' : fMax}</h2>
      <ul className="c-recommendations">
        {recommenationsSorted.map((item, index) => <Recommendation {...item} key={`recommendation-${index}`} />)}
      </ul>
    </>
  )
}

export default Recommendations
