import React from 'react'

import { ReactComponent as FileIcon } from './file.svg';

import './Print.scss'

const Print = () => {
  const printPage = () => {
    window.print()
  }

  return (
    <button className="c-print" type="button" onClick={printPage}>
      Print <span className="u-sr-only">this page</span>
      <FileIcon />
    </button>
  )
}

export default Print
