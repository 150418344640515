import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Header from '../Header/Header'
import Hero from '../Hero/Hero'
import SearchForm from '../SearchForm/SearchForm'
import Syndrome from '../Syndrome/Syndrome'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import NotFound from '../NotFound/NotFound'

import firebase from '../../firebase-config.js'

import 'antd/dist/antd.css'
import './App.scss'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      age: null,
      currentSyndrome: null,
      syndromes: [],
      lookup: {}
    }
  }

  componentDidMount () {
    const ref = firebase.database().ref().orderByKey()
    ref.once('value', snapshot => {
      const syndromes = []
      const lookup = {}

      snapshot.forEach(child => {
        const values = child.val()

        const syndromeName = values["Display Name"] || ''

        if (syndromeName.length) {
          syndromes.push({
            label: syndromeName,
            value: syndromeName
          })

          if (!lookup[syndromeName]) {
            lookup[syndromeName] = child.key
          }

          // Syndrome aliases
          for( const key in values["Synonyms"] ) {
            const alias = values["Synonyms"][key] || ''

            if (alias.length) {
              syndromes.push({
                label: alias,
                value: `${syndromeName}-${alias}`
              })

              if (!lookup[`${syndromeName}-${alias}`]) {
                lookup[`${syndromeName}-${alias}`] = child.key
              }
            }
          }
        }
      })

      syndromes.sort((a, b) => {
        const labelA = a.label.toLowerCase()
        const labelB = b.label.toLowerCase()
        if (labelA > labelB) return 1
        if (labelB > labelA) return -1
        return 0
      })

      this.setState({syndromes, lookup})
    })
  }

  render () {
    return (
      <Router>
        <Header />
        <main>
          <Hero />
          <section>
            <div className="o-container">
              <SearchForm
                syndromes={this.state.syndromes}
                lookup={this.state.lookup} />

              <Switch>
                <Route exact path="/" component={About} />
                <Route path="/syndrome/:slug/:age?" component={Syndrome} />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </section>
          <Contact />
        </main>
        <Footer />
      </Router>
    )
  }
}

export default App
