import firebase from 'firebase/app'
import 'firebase/database'

// Initialize Firebase

firebase.initializeApp({
  apiKey: "AIzaSyBUZL4F5Lwf8YSF5mTpeAN1gQCImZYr_L4",
  authDomain: "skan-planner.firebaseapp.com",
  databaseURL: "https://skan-planner-default-rtdb.firebaseio.com",
  projectId: "skan-planner",
  storageBucket: "skan-planner.appspot.com",
  messagingSenderId: "1021523350677",
  appId: "1:1021523350677:web:d04069706b2ebc6a58779c"
})

export default firebase
