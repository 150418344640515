import React from 'react'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className="c-footer">
      <div className="o-container">
        <p>The SKAN Planner is intended to give easy access to information about care recommendations for children with syndromes which are associated with increased risk for cancer. The content, information, opinions, and viewpoints contained in these educational materials are those of the authors or contributors of materials which are cited and available in published or web-based formats. While the SKAN Planner team reviews and screens these materials, we (Dana-Farber/Boston Childrens Cancer and Blood Disorders Center) makes no warranty, expressed or implied, as to the completeness or accuracy of the content contained in the educational materials or on this website, and will not be responsible for any errors, omissions, or inaccuracies in these materials, whether arising through negligence, oversight, or otherwise. The SKAN Planner and the materials it contains are not intended to substitute for a medical provider’s knowledge, expertise and professional judgment in providing clinical services and support to patients. If you are a patient or family member, you should evaluate the information provided in these materials together with your physician or another qualified healthcare professional and consult with your physician about ongoing clinical care.</p>
        <p>Copyright &copy; 2023 Dana-Farber Cancer Institute, Inc. and The Children's Hospital Corporation d/b/a Boston Children's Hospital All Rights Reserved.</p>
        <p>
        <a href="https://www.dana-farber.org/terms-of-use/" target="_blank" rel="noopener">Terms of Use</a> | <a href="https://www.danafarberbostonchildrens.org/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a> | <a href="https://www.dana-farber.org/accessibility/" target="_blank" rel="noopener">Accessibility</a>
        </p>
      </div>
    </footer>
  )
}
export default Footer
