import React from 'react'

import './Contact.scss'

const Contact = () => {
  return (
    <section className="c-contact">
      <div className="o-container">
        <div className="c-contact__row">
          <div className="c-contact__col">
            <h2 className="c-contact__heading">Research and recommendations are always changing. </h2>
            <p className="c-contact__text">Looking to contribute recommendations? Know of some helpful resources? Have feedback?</p>
          </div>
          <div className="c-contact__col">
            <a className="c-contact__btn" href="mailto:SKANPlanner@dfci.harvard.edu?subject=Get in touch with SKAN Planner" target="_blank" rel="noopener noreferrer">Get in Touch</a>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Contact