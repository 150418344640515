import React from 'react'

import './About.scss'

const About = () => {
  document.title = 'SKAN Planner App'

  return (
    <div className="c-about">
      <div className="c-about__row">
        <div className="c-about__col">
          <figure className="c-about__image">
            <img src="/img/about/about.png" alt="An org chart" />
          </figure>
        </div>
        <div className="c-about__col">
          <h2 className="c-about__heading">About the Project</h2>
          <p>For children with cancer predisposition syndromes, comprehensive surveillance for early tumor detection is an integral part of clinical care. We developed a tool to help pediatricians, pediatric oncologists, genetic counselors, and others to develop surveillance plans for children at risk and to find easy-to-access links to relevant resources. In 2017, the American Association for Cancer Research published the “<a href="https://clincancerres.aacrjournals.org/pediatricseries" target="_blank" rel="noopener noreferrer">Clinical Cancer Research Pediatric Oncology Series.</a>” Authored by a panel of international experts, these manuscripts provided the first comprehensive overview of syndrome-specific surveillance guidelines for children at risk for cancer due to a known or suspected predisposition syndrome. Using these manuscripts, along with other published guidelines, we developed an easy to use tool to help apply these guidelines for streamlined patient care.  Of note, for children who already have a diagnosis of cancer, guidelines for cancer care are not included here.</p>

          <a href="http://www.danafarberbostonchildrens.org/" target="_blank" rel="noopener noreferrer"><img className="c-about__logo" src="/img/danafarber_bostonchildrens_logo.png" alt="Dana-Farber/Boston Children’s Cancer and Blood Disorders Center" /></a>
        </div>
      </div>
    </div>
  )
}

export default About
