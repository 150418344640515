import React from 'react'
import { withRouter } from 'react-router-dom'
import { Select } from 'antd'

import './SearchForm.scss'

const { Option } = Select

class SearchForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      syndrome: null,
      age: null,
      ages: [...Array(19).keys()],
      submitted: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onSyndromeChange = this.onSyndromeChange.bind(this)
    this.onAgeChange = this.onAgeChange.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({ submitted: true })

    if (this.state.syndrome) {

      const lookupValue = this.props.lookup[this.state.syndrome] ? this.props.lookup[this.state.syndrome] : null

      if (lookupValue) {
        this.props.history.push(`/syndrome/${lookupValue}`, this.state.age)
      }
    }

    // Scrolls syndrome into view.
    if (document.querySelector('.c-syndrome')) {
      document.querySelector('.c-syndrome').scrollIntoView({ behavior: 'smooth' })
    }
  }

  onSyndromeChange(value) { this.setState({ syndrome: value }) }

  onAgeChange(value) { this.setState({ age: value }) }

  render() {
    return (
      <form id="search" className="c-search" role="search" onSubmit={this.onSubmit}>

        <div className="c-search__item">
          <label htmlFor="syndrome-input" className="u-sr-only">Search for a syndrome</label>
          <Select
            showSearch
            placeholder="Search for a Syndrome"
            onChange={this.onSyndromeChange}
            id="syndrome-input"
            size="large"
            options={this.props.syndromes}
            value={this.state.syndrome}
            aria-describedby="syndromeError"
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          </Select>
          {this.state.submitted && !this.state.syndrome ? (
            <p id="syndromeError" className="c-search__error">A valid syndrome must be selected</p>
          ) : null}
        </div>

        <div className="c-search__item">
          <label htmlFor="age-input" className="u-sr-only">Select patient age</label>
          <Select
            allowClear
            placeholder="Select patient age"
            onChange={this.onAgeChange}
            id="age-input"
            size="large"
            value={this.state.age}>
            {this.state.ages.map(age => <Option key={age} value={age}>{age === 0 ? 'Newborn' : `Age ${age}`}{age === 18 ? '+' : ''}</Option>)}
          </Select>
        </div>

        <div className="c-search__item">
          <button
            type="submit"
            className="c-search__btn"
            disabled={this.state.submitting}>
            <svg aria-hidden="true" width="20" height="21" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <defs>
                <path d="M11.71.573C9.47 1.29 8 3.054 7.3 5.865 6.251 10.08 4.952 10.618.72 9.119c1.52 2.807 4.6 3.932 6.58 3.932 1.98 0 7.11-2.757 6.522-7.998A21.678 21.678 0 0011.71.573z" id="a" />
                <path d="M7.92 0a7.92 7.92 0 016.26 12.77l5.53 5.54a.99.99 0 01-1.4 1.4l-5.53-5.538A7.92 7.92 0 117.92 0zm0 1.98a5.94 5.94 0 100 11.879 5.94 5.94 0 000-11.88z" id="c" />
              </defs>
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 2.5)">
                  <mask id="b" fill="#fff"><use xlinkHref="#a" /></mask>
                  <use fill="#D8D8D8" xlinkHref="#a" />
                  <g mask="url(#b)" fill="#F7FAFF"><path d="M-3-4h24v24H-3z" /></g>
                </g>
                <g transform="translate(0 .5)">
                  <mask id="d" fill="#fff"><use xlinkHref="#c" /></mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#c" /><g mask="url(#d)" fill="#061B70">
                    <path d="M-2-2h24v24H-2z" />
                  </g>
                </g>
              </g>
            </svg>
            Search
          </button>
        </div>
      </form>
    )
  }
}

export default withRouter(SearchForm)
