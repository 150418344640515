import React from 'react'
import { Link } from "react-router-dom"

import { ReactComponent as Logo } from './skan-planner-logo.svg';

import './Header.scss'

const Header = () => {
  return (
    <header className="c-header">
      <div className="o-container">

        <div className="c-header__row">
          <div className="c-header__col">
            <Link to="/" className="c-logo">
              <Logo />
            </Link>
          </div>
          <div className="c-header__col">
            <div className="c-dfci-logo">
              <p>A Project Of</p>
              <a href="http://www.danafarberbostonchildrens.org/" target="_blank" rel="noopener noreferrer"><img src="/img/danafarber_bostonchildrens_logo.png" alt="Dana-Farber/Boston Children’s Cancer and Blood Disorders Center" /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
