import React from 'react'

import './Hero.scss'

const Hero = () => {
  return (
    <section className="c-hero">
      <img className="c-hero__image" src="/img/hero/helix.png" alt="A double helix" />
      <div className="o-container">
        <div className="c-hero__content">
          <h1>Surveillance Plans for Pediatric Patients with Cancer Predisposition Syndromes:</h1>
          <p>Choose a syndrome or associated gene name and the patient’s age to search guidelines.</p>
        </div>
      </div>
    </section>
  )
}
export default Hero
