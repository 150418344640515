import React from 'react'

import '../Recommendations/Recommendations.scss'


const AllAgesTable = ({ recommendations, ageRanges }) => {
  if (!recommendations) return null

  return (
    <div className="c-recommendations-table">
      <div className="c-recommendations-table__wrapper">
        <table>
          <thead>
            <tr>
              <th width="18%">Recommendations</th>
              {ageRanges && ageRanges.map((range, index) => {
                const pieces = range.value.split('-')
                const min = Number(pieces[0])
                const max = Number(pieces[1])

                return <th key={`heading-${index}-${min}`}>
                  {min === 0 && max === 0 ? 'Prenatal' : (
                    <>
                      {min === 0 ? 'Newborn' : `${min}`}

                      {max && max !== min ? (
                        max === 100 ? ' Years+' : ` - ${max} years`
                      ) : null}

                      {max && max === min ? ' Years' : ''}
                    </>
                  )}
                </th>
              })}
            </tr>
          </thead>
          <tbody>
          {Object.keys(recommendations).map( key => {
            const frequencies = recommendations[key]['frequencies'];
            const caveats = recommendations[key]['caveats'];
            return (
              <tr key={`s-${key}`}>
                <td>{key}</td>
                {frequencies && frequencies.map( (f, index) => {
                  const caveat = caveats[index];
                  return (
                    <td key={`r-${key}-${index}`}>
                      {f}
                      {caveat && caveat.length ? (
                        <em>{caveat}</em>
                      ) : null}
                    </td>
                  )
                })}
              </tr>
          )})}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AllAgesTable
