import React from 'react'

import { ReactComponent as MailIcon } from './mail.svg'

import './Share.scss'

const Share = () => {
  return (
    <a className="c-share"
      href={`mailto:?body=The following link will take you to the surveillance recommendations discussed during your appointment: ${window.location.href}`}
      target="_blank" rel="noopener noreferrer">
        Share Results
      <MailIcon />
    </a>
  )
}

export default Share
