import React from 'react';

import { ReactComponent as TableIcon } from './icon-table.svg';
import { ReactComponent as TimelineIcon } from './icon-timeline.svg';

import './TableHeading.scss'

const TableHeading = ({view, clickHandler}) => {
  const isTableView = view === 'table';
  const isTimelineView = view === 'timeline';

  return (
    <div className="c-table-heading">
      <h2 className="c-recommendations__heading">Recommendations for All ages</h2>
      <h2 className="c-table-heading__header">
        Surveillance {view === 'table' ? 'Summary' : 'Timeline'}
      </h2>
      <ul className="c-table-heading__buttons">
        <li>
          <button
            type="button"
            disabled={isTableView}
            onClick={() => {
              clickHandler('table');
            }}
          >
            <TableIcon />
            <span className="u-sr-only">See Surveillance table view</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            disabled={isTimelineView}
            onClick={() => {
              clickHandler('timeline');
            }}
          >
            <TimelineIcon />
            <span className="u-sr-only">See Surveillance timeline view</span>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default TableHeading;