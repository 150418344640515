import React, { useState } from 'react'

import { ReactComponent as ExtIcon } from './external.svg';

import './Stats.scss'

const Stats = ({geneReviews, references, synonyms, relatedSyndromes, familyResources}) => {

  const [expanded, setExpanded] = useState(false)

  return (
    <div className="c-stats__row">
      <div className="c-stats__col">
        <dl className="c-stats">
          {geneReviews && geneReviews.length ? (
            <div className="c-stat" id="genereview">
              <dt className="c-stat__name">GeneReviews<span>&reg;</span>:</dt>
              <dd>{geneReviews.map( (review, index) => <a key={index} className="c-stat__link" href={review.url} target="_blank" rel="noopener noreferrer">{review.name} <ExtIcon /></a>)}</dd>
            </div>
          ) : null}

          {synonyms && synonyms.length  ? (
            <div className="c-stat" id="aka">
              <dt className="c-stat__name">Syndrome Names and Related Genes:</dt>
              <dd>{synonyms.map( (synonym, index) => <span key={index} className="c-stat__aka">{synonym}</span>)}</dd>
            </div>
          ) : null}

          {relatedSyndromes && relatedSyndromes.length ? (
            <div className="c-stat" id="related-syndromes">
              <dt className="c-stat__name">Related Syndromes:</dt>
              <dd>{relatedSyndromes.map( (related, index) => <a key={index} className="c-stat__link" href={related.url} target="_blank" rel="noopener noreferrer">{related.name} <ExtIcon /></a>)}</dd>
            </div>
          ) : null}
        </dl>
      </div>
      <div className="c-stats__col">
        <dl className="c-stats">
          {references && references.length ? (
            <div className="c-stat" id="references">
              <dt className="c-stat__name">References:</dt>
              <dd className="c-stat__data">
                {references.length > 2 && !expanded ? (
                  <>
                    <a key={0} className="c-stat__link" href={references[0].url} target="_blank" rel="noopener noreferrer">
                    {references[0].name} <ExtIcon />
                    </a>

                    <a key={1} className="c-stat__link" href={references[1].url} target="_blank" rel="noopener noreferrer">
                    {references[1].name} <ExtIcon />
                    </a>

                    <button className="c-stat__expand" type="button" onClick={() => setExpanded(!expanded)}>
                      Show more <span className="u-sr-only">references links</span>
                    </button>
                  </>
                ) : references.map( (reference, index) => <a key={index} className="c-stat__link" href={reference.url} target="_blank" rel="noopener noreferrer">
                  {reference.name} <ExtIcon />
                </a>)}
              </dd>
            </div>
          ) : null}

          {familyResources ? (
            <div className="c-stat" id="family-resources">
              <dt className="c-stat__name">Family Resources:</dt>
              <dd>{familyResources.map( (resource, index) => <a key={index} className="c-stat__link" href={resource.url} target="_blank" rel="noopener noreferrer">{resource.name} <ExtIcon /></a>)}</dd>
            </div>
          ) : null}
        </dl>
      </div>
    </div>
  )
}

export default Stats
