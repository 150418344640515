import React from 'react'

import './NotFound.scss'

const NotFound = () => {
  document.title = 'Page not found | SKAN Planner App'
  return (
    <div className="c-404">
      <h1 className="c-404__heading">404 Page not found</h1>
      <p className="c-404__text">That url could not be found please double check it and try again.</p>
    </div>
  )
}

export default NotFound
