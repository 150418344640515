import React from 'react';

import './RecommendationsTimeline.scss'

const TimelineHeader = ({ ageRanges }) => {
  return <thead className="c-timeline__header">
    <tr>
      <th></th>
      {ageRanges && ageRanges.map((range, index) => {
        const rangePieces = range.value.split('-');
        const min = Number(rangePieces[0])
        const max = Number(rangePieces[1])

        return <th className="c-timeline__heading" key={`heading-${index}-${min}`}>
          {min === 0 && max === 0 ? 'Prenatal' : (
            <>
              {min === 0 ? 'Newborn' : `${min} years${max === 100 ? '+' : ''}`}
            </>
          )}
        </th>
      })}
    </tr>
  </thead>
}

const TimelineFooter = ({ ageRanges }) => {
  return <tfoot className="c-timeline__footer">
    <tr>
      <th></th>
      {ageRanges && ageRanges.map((range, index) => {
        const rangePieces = range.value.split('-');
        const min = Number(rangePieces[0])
        const max = Number(rangePieces[1])

        return <th className="c-timeline__heading" key={`footer-${index}-${min}`}>
          {min === 0 && max === 0 ? 'Prenatal' : (
            <>
              {min === 0 ? 'Newborn' : `${min} years${max === 100 ? '+' : ''}`}
            </>
          )}
        </th>
      })}
    </tr>
  </tfoot>
}

const uniqueNotNull = (v, i, a) => a.indexOf(v) === i && v !== null;

const RecommendationsTimeline = ({recommendations, ageRanges}) => {
  if (!recommendations) return null

  return (
    <div className="c-timeline__wrapper">
      <table className="c-timeline">
        <TimelineHeader ageRanges={ageRanges} />

        <tbody className="c-timeline__body">
          {Object.keys(recommendations).map( (key, rIndex) => {
            const frequencies = recommendations[key]['frequencies'];
            const caveats = recommendations[key]['caveats'];

            const frequenciesLabels = frequencies.filter(uniqueNotNull);
            const caveatsLabels = caveats.filter(uniqueNotNull);

            let altColor = false;

            return (
              <tr key={`recommendation-${rIndex}`}>
                {frequencies && frequencies.map( (f, index) => {
                  let start = false;
                  let end = false;
                  let alt = false;

                  const prevFrequency = frequencies[index - 1];
                  const nextFrequency = frequencies[index + 1];

                  if (prevFrequency !== frequencies[index]) {
                    start = true;
                  }

                  if (nextFrequency !== frequencies[index]) {
                    end = true;
                  }

                  if (index !== 0 && start && prevFrequency !== null && frequencies[index] !== null) {
                    altColor = true;
                  }
                  const thisCaveat = caveats[index];
                  return (
                    <>
                      {index === 0 ? (
                        <td><span className="c-timeline__label">{key}</span></td>
                      ) : null}
                      <td className={caveatsLabels && caveatsLabels.length ? 'has-caveat' : ''} key={`r-${key}-${index}`}>

                        {frequencies[index] === null ? (
                          <span className={`c-timeline__pill c-timeline__pill--empty ${start ? 'c-timeline__pill--start' : ''} ${end ? 'c-timeline__pill--end' : ''}`}></span>
                        ) : (
                          <span className={`c-timeline__pill ${start ? 'c-timeline__pill--start' : ''}  ${end ? 'c-timeline__pill--end' : ''} ${alt ? 'c-timeline__pill--alt' : ''} ${altColor ? 'c-timeline__pill--alt' : ''}`}></span>
                        )}

                        {start ? (
                          <>
                            {f ? (
                              <span className="c-timeline__frequency">{f}</span>
                            ) : null}

                            {thisCaveat ? (
                              <span className="c-timeline__caveats"><em>{thisCaveat}</em></span>
                            ) : null}
                          </>
                        ) : null}

                        {false && index === 0 ? (
                          <span className="c-timeline__label-wrapper">
                            <span className="c-timeline__label">{key}</span>
                            {frequenciesLabels && frequenciesLabels.length ? (
                              <span className="c-timeline__frequency">{frequenciesLabels.join(', ')}</span>
                            ) : null}

                            {caveatsLabels && caveatsLabels.length ? (
                              <span className="c-timeline__caveats"><em>{caveatsLabels.join(', ')}</em></span>
                            ) : null}
                          </span>
                        ) : null}

                      </td>
                    </>
                  )
                })}
              </tr>
            )
          })}
        </tbody>

        <TimelineFooter ageRanges={ageRanges} />
      </table>
    </div>
  )
}

export default RecommendationsTimeline;