import React from 'react'
import { withRouter } from 'react-router-dom'
import uniq from 'lodash/uniq'

import Stats from './components/Stats/Stats'
import Sort from './components/Sort/Sort'
import Recommendations from './components/Recommendations/Recommendations'
import AllAgesTable from './components/AllAgesTable/AllAgesTable'
import TableHeading from './components/TableHeading/TableHeading'
import RecommendationsTimeline from './components/RecommendationsTimeline/RecommendationsTimeline'
import Utils from './components/Utils/Utils'

import firebase from '../../firebase-config.js'

import './Syndrome.scss'

class Syndrome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      slug: null,
      searchAge: null,
      useSearchAge: false,
      ageRange: null,
      displayName: null,
      lastUpdated: null,
      references: null,
      recommendations: null,
      synonyms: null,
      geneReviews: null,
      familyResources: null,
      relatedSyndromes: null,
      ageOptions: [],
      view: 'table'

    }

    this.fetchFromFirebase = this.fetchFromFirebase.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.buildOptions = this.buildOptions.bind(this)
    this.setSelectedRange = this.setSelectedRange.bind(this)
    this.setSearchAge = this.setSearchAge.bind(this)
    this.setTableView = this.setTableView.bind(this)
  }

  componentDidMount() {
    this.fetchFromFirebase(() => {
      this.setSelectedRange(this.props.match.params.age)
      this.setSearchAge(this.props.location.state)
      if (document.querySelector('.c-syndrome')) {
        document.querySelector('.c-syndrome').scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.fetchFromFirebase(() => {
        // Age from search form
        if (this.props.location.state !== prevProps.location.state) {
          this.setSearchAge(this.props.location.state)
        }

        // Age from url param
        if (this.props.match.params.age !== prevProps.match.params.age) {
          this.setSelectedRange(this.props.match.params.age)
        }
      })

    } else {
      // Age from search form
      if (this.props.location.state !== prevProps.location.state) {
        this.setSearchAge(this.props.location.state)
      }

      // Age from url param
      if (this.props.match.params.age !== prevProps.match.params.age) {
        this.setSelectedRange(this.props.match.params.age)
      }
    }
  }

  fetchFromFirebase(callBack = () => { }) {
    const { slug } = this.props.match.params

    const reference = firebase.database().ref().child(`${slug}`)

    reference.once('value', snapshot => {
      if (!snapshot.exists()) {
        this.props.history.push('/404')
      }

      let displayName = null
      let lastUpdated = null
      const geneReviews = []
      const references = []
      const familyResources = []
      const relatedSyndromes = []
      const recommendations = []
      const synonyms = []

      snapshot.forEach(child => {
        const childValue = child.val()

        if (child.key === 'Display Name') {
          displayName = childValue
        }

        if (child.key === 'Last Updated') {
          lastUpdated = childValue
        }

        if (child.key === 'GeneReviews') {
          for (let key in childValue) {
            geneReviews.push({
              name: key,
              url: childValue[key]
            })
          }
        }

        if (child.key === 'References') {
          for (let key in childValue) {
            references.push({
              name: key,
              url: childValue[key]
            })
          }
        }

        if (child.key === 'Family Resources') {
          for (let key in childValue) {
            familyResources.push({
              name: key,
              url: childValue[key]
            })
          }
        }

        if (child.key === 'Related Syndromes') {
          for (let key in childValue) {
            relatedSyndromes.push({
              name: key,
              url: childValue[key]
            })
          }
        }

        if (child.key === 'Recommendations') {
          for (let key in childValue) {
            recommendations.push({
              description: childValue[key]['Description'],
              frequency: childValue[key]['Frequency'],
              caveat: childValue[key]['Caveat'] || '',
              min: childValue[key]['Min'],
              max: childValue[key]['Max']
            })
          }
        }

        if (child.key === 'Synonyms') {
          for (let key in childValue) {
            synonyms.push(childValue[key])
          }
        }
      })

      this.setState({
        slug,
        displayName,
        lastUpdated,
        geneReviews,
        references,
        familyResources,
        relatedSyndromes,
        recommendations,
        synonyms
      })

      this.buildOptions(recommendations)

      document.title = displayName + ' | SKAN Planner App'

      callBack()
    })
  }

  handleSort(value) {
    if (value === 'All') {
      this.props.history.push(`/syndrome/${this.state.slug}`)
    } else {
      this.props.history.push(`/syndrome/${this.state.slug}/ages-${value}`)
    }

    this.setState({
      ageRange: value,
      useSearchAge: false
    })
  }

  buildOptions(recommendations) {
    /*
      Create a range of ages from the recommendation min/max data.
      Recommendation data is stored in the following format:
      {
        "Description" : "Thyroid ultrasound",
        "Frequency" : "If normal every three years",
        "Max" : 100,
        "Min" : 9
      }

      To create the ranges, we make 3 arrays
      1. uniqMins: Unique sorted min values of all recommendations
      2. uniqMaxes: Unique sorted max values of all recommendations
      3. uniqAges: Unique sorted all min and max values of all recommendations

      We iterate through the uniqAges array
      n is the current item in the array
      next is the next item in the array

      we check if n is in the uniqMins and uniqMaxes array
      we check if next is in the uniqMins and uniqMaxes array

      if n is 0 and next is 0 they get added to the age ranges as Prenatal

      if n is present in uniqMins and next is present in uniqMaxes and they are not equal
        n and next get added to the age ranges

      if n is present in uniqMaxes and end is present in uniqMins and n+1 less than next-1
        n+1 next-1 gets added to the age ranges

      if n is present in uniqMins and next is present in uniqMins and n less than next -1
        n next-1 gets added to the age ranges

      if n is present in uniqMaxes and next is present in uniqMaxes and n+1 is less than next
        n+1 next gets added to the age ranges

      Real Example DICER1:
      Abdominal and Pelvic Ultrasound – Min: 0 – Max: 9
      Abdominal and Pelvic Ultrasound – 9 – 100
      Chest CT – 0 – 4
      Chest CT – 5 – 100
      Chest Xray – 9 – 12
      Chest Xray – 0 – 8
      Dilated Eye Exam – 3 – 9
      ENT evaluation  – 0 – 100
      Physical Exam – 0 – 9
      Physical Exam – 9 – 100
      Third-trimester Ultrasound – 0 – 0
      Thyroid ultrasound – 8 – 100

      Has the following ranges:
      Prenatal range: 0-0
      Ages 1-2 range: 1-2
      Ages 3-4 range: 3-4
      Ages 5-8 range: 5-8
      Ages 8-9 range: 8-9
      Ages 9-12 range: 9-12
      Ages 13-Adulthood range: 13-100
    */

    let ageOptions = []
    if (recommendations) {
      const mins = []
      const maxes = []

      recommendations.forEach((recommendation) => {
        const { min, max } = recommendation

        if (min >= 0 && max >= 0) {
          mins.push(min)
          maxes.push(max)
        }
      })

      mins.sort((a, b) => a - b)
      maxes.sort((a, b) => a - b)

      const uniqMins = uniq(mins)
      const uniqMaxes = uniq(maxes)

      const uniqAges = [...uniqMins, ...uniqMaxes].sort((a, b) => a - b)

      let validRange = false
      if (uniqMins.length === uniqMaxes.length) {
        uniqMins.forEach((min, index) => {
          const max = uniqMaxes[index]
          const nextMin = uniqMins[index + 1]
          if (nextMin) {
            if (nextMin === max || Math.abs(nextMin - max) === 1) {
              validRange = true
            }
          }
        })
      }

      if (validRange) {
        uniqMins.forEach((n, index) => {
          const max = uniqMaxes[index]
          ageOptions.push({
            label: `Ages ${n === 0 ? 'Newborn' : n}-${max === 100 ? 'Adulthood' : max}`,
            value: `${n}-${max}`
          })
        })
      } else {
        uniqAges.forEach((n, index) => {
          const next = uniqAges[index + 1]

          if (n === 0 && next === 0) {
            ageOptions.push({
              label: `Prenatal`,
              value: `${n}-${next}`
            })
          }

          if (next) {
            const startIsMin = uniqMins.includes(n)
            const startIsMax = uniqMaxes.includes(n)

            const endIsMin = uniqMins.includes(next)
            const endIsMax = uniqMaxes.includes(next)

            if (n <= next) {
              if ((startIsMin && endIsMax) && n !== next) {
                ageOptions.push({
                  label: `Ages ${n === 0 ? 'Newborn' : n}-${next === 100 ? 'Adulthood' : next}`,
                  value: `${n}-${next}`
                })
              } else if (startIsMax && endIsMin && (n + 1 < next - 1)) {
                ageOptions.push({
                  label: `Ages ${n + 1}-${next === 100 ? 'Adulthood' : next - 1}`,
                  value: `${n + 1}-${next - 1}`
                })
              } else if (startIsMin && endIsMin && (n < next - 1)) {
                ageOptions.push({
                  label: `Ages ${n === 0 ? 'Newborn' : n}-${next === 100 ? 'Adulthood' : next - 1}`,
                  value: `${n}-${next - 1}`
                })
              } else if (startIsMax && endIsMax && (n + 1 < next)) {
                ageOptions.push({
                  label: `Ages ${n + 1}-${next === 100 ? 'Adulthood' : next}`,
                  value: `${n + 1}-${next}`
                })
              }
            }
          }
        })
      }

      ageOptions.unshift({
        label: 'See All Ages',
        value: 'All'
      })
    }
    this.setState({ ageOptions })
  }

  setSelectedRange(age) {
    this.setState({
      ageRange: age ? age.replace('ages-', '') : 'All'
    })
  }

  setSearchAge(age) {
    this.setState({
      searchAge: age >= 0 ? age : null,
      useSearchAge: age >= 0 ? true : false
    })

    if (age >= 0 && this.state.ageOptions) {
      let foundRange = null
      let foundRangeCount = 0
      this.state.ageOptions.slice(1).forEach(option => {
        const [min, max] = option.value.split('-')
        const minInt = parseInt(min)
        const maxInt = parseInt(max)
        const ageInt = parseInt(age)

        if (ageInt >= minInt && ageInt <= maxInt) {
          foundRange = option.value
          foundRangeCount += 1
        }
      })

      if (foundRange && foundRangeCount < 2) {
        this.handleSort(foundRange)
      }
    }
  }

  setTableView(view) {
    this.setState({
      view: view
    })
  }

  render() {
    const { view, recommendations } = this.state;

    if (!Array.isArray(recommendations)) return null;

    if (recommendations && recommendations.length) {
      recommendations.sort((a, b) => {
        if (a.description > b.description) return 1
        if (b.description > a.description) return -1
        return 0
      });
    }

    const ageRanges = this.state.ageOptions.slice(1);

    const recommendationsFormatted = {};
    recommendations.forEach((recommendation) => {
      const { description, frequency, caveat, min: rMin, max: rMax } = recommendation;

      if (!recommendationsFormatted[description]) {
        recommendationsFormatted[description] = {
          frequencies: Array(ageRanges.length).fill(null),
          caveats: Array(ageRanges.length).fill(null)
        };
      }

      ageRanges.forEach((range, rangeIndex) => {
        const rangePieces = range.value.split('-')
        const min = Number(rangePieces[0])
        const max = Number(rangePieces[1])

        if (rangeIndex === 0 && min === 0 && max === 0) {
          if (min === rMin && max === rMax) {
            recommendationsFormatted[description]['frequencies'][rangeIndex] = frequency || null;
            recommendationsFormatted[description]['caveats'][rangeIndex] = caveat || null;
          }
        } else if (min >= rMin && max <= rMax) {
          recommendationsFormatted[description]['frequencies'][rangeIndex] = frequency || null;
          recommendationsFormatted[description]['caveats'][rangeIndex] = caveat || null;
        }
      });
    });

    return (
      <div className="c-syndrome">

        <Utils {...this.state} />

        {this.state.displayName ? (
          <h1 className="c-syndrome__name">{this.state.displayName}</h1>
        ) : null}

        <Stats {...this.state} />

        <Sort
          changeHandler={this.handleSort}
          options={this.state.ageOptions}
          selected={this.state.ageRange} />

        {this.state.recommendations ?
          this.state.ageRange === 'All' ? (
            <>
              <TableHeading view={view} clickHandler={this.setTableView} />
              {view === 'table' ? (
                <AllAgesTable
                  recommendations={recommendationsFormatted}
                  ageRanges={ageRanges}
                />
              ) : (
                  <RecommendationsTimeline
                    recommendations={recommendationsFormatted}
                    ageRanges={ageRanges}
                  />
                )}
            </>
          ) : (
              <Recommendations {...this.state} />
            )
          : null}

      </div>
    )
  }
}

export default withRouter(Syndrome)
